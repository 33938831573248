import * as React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import InspirationItem from '../../components/inspiration/InspirationItem';
import Layout from '../../components/layouts/core';
import SeriesPage from '../../components/series/SeriesPage';

import yellowBlueOrange from '../../data/CollectionItems/yellowBlueOrange';

import yellowBlueOrangeCarouselPiece from '../../data/CarouselPieces/yellowBlueOrangeCarouselPiece';

import getImagesFromNodes from '../../utils/getImagesFromNodes';

export const pageQuery = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/yellow-blue-orange-rothko-print/" } }) {
      nodes {
        name
        relativePath
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;

const ogDescription = `Boogie-Woogie is an NFT collection from Dandy Lines.
In our Run 1 Rothko series, we reference the other most influential artist of our collection: Mark Rothko.
We are inspired by Rothko's ability to invoke a human response using a variety of fields of color. We pull
one this yellow, blue and orange composition as it's one of our favorites.`;

const RunOneRothkoPage = ({ data }) => {
  const images = getImagesFromNodes(data.allFile.nodes);

  return (
    <>
      <Helmet>
        <title>Boogie-Woogie | Run 1 Rothko Series</title>
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@BoogieWoogieNFT' />
        <meta name='twitter:creator' content='@ics_jim' />
        <meta property='og:url' content='https://boogie-woogie.io/series/run-1-rothko' />
        <meta property='og:title' content='Boogie-Woogie | Run 1 Rothko Series' />
        <meta property='og:type' content='website' />
        <meta property='og:description' content={ogDescription} />
        <meta property='og:image' content='https://sale.boogie-woogie.io/boogie-woogie-auction-site-run-one.png' />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
      </Helmet>
      <Layout>
        <SeriesPage details={yellowBlueOrange.details} seriesName='Run 1 Rothko Series' images={images}>
          <InspirationItem
            ReferenceImage={yellowBlueOrange.ReferenceImage}
            details={yellowBlueOrange.details}
            seriesPage={yellowBlueOrange.seriesPage}
            piece={yellowBlueOrangeCarouselPiece}
          />
        </SeriesPage>
      </Layout>
    </>
  );
};

export default RunOneRothkoPage;
