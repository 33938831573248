import padPrintNo from './padPrintNo';
import padRunNo from './padRunNo';
import seriesByDisplay from './seriesByDisplay';
import seriesNumeric from './seriesNumeric';

// Token ID: GENERATION - RUN - SERIES - PRINT
// 1 01 01 123
// Generation has 1970 units (prints) (345 Bespoke)
// Run      - Quarterly  - 125 units (prints) 13 runs
// Series   - 01 - 99    - 5 series in a run
// Print    - 001 - 999  - 25 prints per series
// Bespoke  - 10000001
//            10000002

const generateTokenId = (runNo, seriesDisplay, printNo) => {
  const run = padRunNo(runNo);
  const series = seriesNumeric(seriesByDisplay(seriesDisplay));
  const print = padPrintNo(printNo);

  return parseInt(`1${run}${series}${print}`, 10);
};

export default generateTokenId;
