import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

const ChevronLeft = ({ aspect = '2rem', color = colors.yellowDesaturated }) => (
  <SVGWrapper viewBox='0 0 24 24' aspect={aspect} stroke={color}>
    <polyline points="15 18 9 12 15 6"></polyline>
  </SVGWrapper>
);

export default ChevronLeft;
