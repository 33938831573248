import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

const Shadowbox = ({ aspect = '2rem', color = colors.yellowDesaturated }) => (
  <SVGWrapper viewBox='0 0 24 24' aspect={aspect} stroke={color}>
    <polyline points='15 3 21 3 21 9'></polyline>
    <polyline points='9 21 3 21 3 15'></polyline>
    <line x1='21' y1='3' x2='14' y2='10'></line>
    <line x1='3' y1='21' x2='10' y2='14'></line>
  </SVGWrapper>
);

export default Shadowbox;
