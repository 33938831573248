const series = {
  'Run 1 Miró Series': 'mural-for-the-terrace-plaza-hotel',
  'Run 1 Matisse Series': 'nu-bleu-ii',
  'Run 1 Mondrian Series': 'new-york-city',
  'Run 1 Van Gogh Series': 'sunflowers',
  'Run 1 Rothko Series': 'yellow-blue-orange',
};

const seriesByDisplay = (seriesDisplay) => series[seriesDisplay];

export default seriesByDisplay;
