import * as React from 'react';

import Shadowbox from '../shadowbox/Shadowbox';

import SeriesPiece from './SeriesPiece';

import colors from '../../colors/colors';

import './series-piece.css';

const sortImages = (images) =>
  images
    .map(({ path }, index) => ({
      path,
      index,
    }))
    .map(({ path, index }) => {
      const printIndex = path
        .split('/')[4]
        .split('-')
        .findIndex((i) => i === 'print');

      const target = parseInt(path.split('/')[4].split('-')[printIndex + 1], 10);

      return {
        target,
        index,
      };
    })
    .sort((a, b) => a.target - b.target)
    .map(({ target, index }) => ({
      ...images[index],
      printNo: target,
    }));

const SeriesPage = ({ details, seriesName, images, children }) => {
  const sortedImages = sortImages(images);

  const [shadowboxImageIndex, setShadowboxImageIndex] = React.useState(undefined);

  const closeOverlay = () => {
    setShadowboxImageIndex(undefined);
  };

  const handleImageSet = (index) => {
    setShadowboxImageIndex(index);
  };

  return (
    <>
      <div
        style={{
          display: 'block',
          width: '100%',
          padding: 'calc(2.5rem + 2.5vw) 0',
          textAlign: 'center',
          backgroundColor: colors.red,
        }}
      >
        <h1
          style={{
            display: 'block',
            width: '100%',
            margin: 0,
            color: colors.white,
            fontWeight: 300,
          }}
        >
          {seriesName}
        </h1>
      </div>
      {children}
      <div
        className='SeriesWrapper'
        style={{
          margin: '0 auto',
          maxWidth: '50rem',
          width: 'calc(100% - 10vw)',
          padding: '0 1rem',
        }}
      >
        <h2>The Series</h2>
        {sortedImages.map(({ image, keyId, name, path, printNo }, index) => (
          <div
            key={`image-list=${keyId}`}
            style={{
              margin: 'calc(1rem + 3vw) 0',
            }}
          >
            <SeriesPiece
              image={image}
              index={index}
              printNo={printNo}
              name={name}
              path={path}
              seriesName={seriesName}
              shadowboxHandler={handleImageSet}
            />
          </div>
        ))}
      </div>
      {shadowboxImageIndex !== 'undefined' && shadowboxImageIndex > -1 && (
        <Shadowbox
          images={sortedImages.map(({ image }) => image)}
          startingCount={shadowboxImageIndex}
          seriesName={seriesName}
          closeOverlay={closeOverlay}
        />
      )}
    </>
  );
};

export default SeriesPage;
