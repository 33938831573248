const series = {
  'new-york-city': '01',
  'nu-bleu-ii': '02',
  'mural-for-the-terrace-plaza-hotel': '03',
  sunflowers: '04',
  'yellow-blue-orange': '05',
};

const seriesNumeric = (seriesId) => series[seriesId];

export default seriesNumeric;
