import * as React from 'react';

import colors from '../../colors/colors';
import SVGWrapper from '../display/SVGWrapper';

const Close = ({ aspect = '2rem', color = colors.yellowDesaturated }) => (
  <SVGWrapper viewBox='0 0 24 24' aspect={aspect} stroke={color}>
    <circle cx='12' cy='12' r='10'></circle>
    <line x1='15' y1='9' x2='9' y2='15'></line>
    <line x1='9' y1='9' x2='15' y2='15'></line>
  </SVGWrapper>
);

export default Close;
