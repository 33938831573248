import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import './shadowbox.css';
import FlexWrapper from '../display/FlexWrapper';
import ChevronLeft from '../icons/ChevronLeft';
import colors from '../../colors/colors';
import ChevronRight from '../icons/ChevronRight';
import Close from '../icons/Close';

// import OpenSea from '../icons/OpenSea';
// import CheckmarkCircle from '../icons/CheckmarkCircle';

const Shadowbox = ({ images, startingCount, seriesName, closeOverlay }) => {
  const [currentIndex, setCurrentIndex] = React.useState(undefined);
  const [currentImage, setCurrentImage] = React.useState(undefined);

  React.useEffect(() => {
    setCurrentImage(images[startingCount]);
    setCurrentIndex(startingCount);
  }, [startingCount]);

  const handleDecrement = () => {
    if (currentIndex > 0) {
      setCurrentImage(images[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    } else {
      setCurrentImage(images[images.length - 1]);
      setCurrentIndex(images.length - 1);
    }
  };

  const handleIncrement = () => {
    if (currentIndex < images.length - 1) {
      setCurrentImage(images[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentImage(images[0]);
      setCurrentIndex(0);
    }
  };

  return (
    <>
      {!!currentImage ? (
        <div className='Shadowbox'>
          <div className='ShadowboxClose' onClick={closeOverlay}>
            <Close aspect='2rem' color='white' />
          </div>
          <FlexWrapper
            align='stretch'
            justify='between'
            style={{ height: 'calc(100% - 4rem)', maxHeight: '600px', margin: '0 0 2rem' }}
          >
            <div onClick={handleDecrement} className='ShadowboxBrowseColumn'>
              <ChevronLeft aspect='3rem' color={colors.white} />
            </div>
            <FlexWrapper
              align='center'
              justify='center'
              style={{
                maxWidth: '600px',
                width: 'calc(100% - 10rem)',
                margin: '0 1rem',
              }}
            >
              <GatsbyImage
                image={currentImage}
                alt={`${seriesName}: Print ${currentIndex + 1} of 25`}
                placeholder='none'
              />
            </FlexWrapper>
            <div onClick={handleIncrement} className='ShadowboxBrowseColumn'>
              <ChevronRight aspect='3rem' color={colors.white} />
            </div>
          </FlexWrapper>
          <FlexWrapper align='center' justify='center' style={{ width: '100%', height: '2rem' }}>
            <div>
              {
                // Will integrate availability
                // <CheckmarkCircle color={colors.green} aspect='1.5rem' />
              }
            </div>
            <p
              style={{
                margin: '0 2rem',
                color: colors.white,
                fontSize: '2rem',
                textAlign: 'center',
                lineHeight: '2rem',
              }}
            >{`${currentIndex + 1}/25`}</p>
            <div>
              {
                // TODO: OpenSea Integration
                // <OpenSea aspect='1.5rem' fog />
              }
            </div>
          </FlexWrapper>
        </div>
      ) : null}
    </>
  );
};

export default Shadowbox;
