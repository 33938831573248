import * as React from 'react';

import colors from '../../colors/colors';

import './inspiration-item-details.css';

const InspirationItem = ({ details, ReferenceImage }) => (
  <div className='InspirationItemWrapper' style={{ borderBottom: `1px solid ${colors.offWhite}` }}>
    <div>
      <h2
        style={{
          color: colors.blue,
        }}
      >
        The Inspiration
      </h2>
      <div className='InspirationItem'>
        <div className='InspirationItemImageBlock'>
          <div className='ReferenceImage'>
            <a className='InspirationLink' href={`${details.link}`}>
              {ReferenceImage}
            </a>
          </div>
          <h3 className='InspirationHeader' style={{ color: colors.blue }}>
            {`Detail of ${details.name}`}
          </h3>
        </div>
        <div className='InspirationItemDetails'>
          <p className='InspirationItemDetailsArtistName'>{details.artistName}</p>
          <p className='InspirationItemDetailsArtistBio'>{details.artistBio}</p>
          <p className='InspirationItemDetailsTitle'>{`${details.name} - ${details.date}`}</p>
          <div className='InspirationItemDetailsBlock'>
            <div>
              <p>Medium:</p>
              <p>{details.medium}</p>
            </div>
            <div>
              <p>Measurements:</p>
              <p>{details.measurements}</p>
            </div>
            <div>
              <p>Classification:</p>
              <p>{details.classification}</p>
            </div>
            <div>
              <p>Credit Line:</p>
              <p>{details.creditLine}</p>
            </div>
            {details.rights && !!details.rights.length && (
              <div>
                <p>Rights:</p>
                <p>{details.rights}</p>
              </div>
            )}
          </div>
          <a className='InspirationLink' href={`${details.link}`}>{`View the Original: ${details.location}`}</a>
        </div>
      </div>
    </div>
  </div>
);

export default InspirationItem;
