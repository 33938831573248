import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import generateTokenId from '../../utils/generateTokenId';
import getPrintNumberFromTokenId from '../../utils/getPrintNumberFromTokenId';
import seriesByDisplay from '../../utils/seriesByDisplay';

import TwoColumns from '../display/TwoColumns';
import FlexWrapper from '../display/FlexWrapper';
import ArrowRightCircle from '../icons/ArrowRightCircle';
import Shadowbox from '../icons/Shadowbox';

import colors from '../../colors/colors';

import './series-piece.css';

const paragraphButtonStyle = {
  color: colors.white,
  fontSize: '0.9rem',
  lineHeight: '0.9rem',
  margin: 0,
};

const linkStyles = {
  display: 'block',
  width: '100%',
  margin: '0 0 0.5rem',
  padding: '0.6rem 1rem',
  border: `1px solid ${colors.red}`,
  backgroundColor: colors.red,
  borderRadius: '0.25rem',
  textDecorationColor: colors.yellowDesaturated,
};

const SeriesPiece = ({ image, index, name, printNo, seriesName, shadowboxHandler }) => {
  const tokenId = generateTokenId(1, seriesName, printNo);

  return (
    <TwoColumns align='center' justify='between' className='SeriesPiece'>
      <div
        onClick={() => {
          shadowboxHandler(index);
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '450px',
          height: '100%',
          maxHeight: '450px',
          cursor: 'pointer',
        }}
      >
        <GatsbyImage image={image} alt={`${seriesName}: Print ${printNo} of 25`} />
      </div>
      <div className='SeriesPieceDetails'>
        <div
          style={{
            width: '100%',
            maxWidth: '30rem',
            margin: '0 auto',
            padding: '2rem',
            backgroundColor: 'hsl(45, 5%, 96%)',
          }}
        >
          <h3
            style={{
              margin: '0 0 0.25rem',
              lineHeight: 1,
              fontSize: '2.5rem',
              fontWeight: 300,
              color: colors.red,
            }}
          >{`#${tokenId}`}</h3>
          <p style={{ color: colors.blue, margin: '0.65rem 0 0.85rem' }}>{`${seriesName} - Print #${printNo}`}</p>
          {/*
        // TODO: Add purchase/ownership status to individual items
        <div
          style={{
            ...coreButtonStyle,
            backgroundColor: colors.green,
          }}
        >
          <p style={paragraphButtonStyle}>Available for Purchase</p>
        </div>
        // TODO: OpenSea Integration
        <div
          style={{
            ...coreButtonStyle,
            // OpenSea 'Dark Sea'
            backgroundColor: '#1868B7',
          }}
        >
          <p style={paragraphButtonStyle}>View on OpenSea</p>
        </div>
        */}
          <a
            className='ExternalLink'
            href={`https://sale.boogie-woogie.io/${seriesByDisplay(seriesName)}/${getPrintNumberFromTokenId(tokenId)}`}
            style={{
              ...linkStyles,
            }}
          >
            <FlexWrapper align='center' justify='between'>
              <span style={{ margin: '0 0.75rem 0 0', color: colors.white, fontWeight: 900 }}>View the Auction</span>
              <ArrowRightCircle aspect='1.5rem' color={colors.white} />
            </FlexWrapper>
          </a>
          <div
            onClick={() => {
              shadowboxHandler(index);
            }}
            style={{
              ...linkStyles,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: colors.blue,
              border: `1px solid ${colors.blue}`,
              cursor: 'pointer',
            }}
          >
            <p style={paragraphButtonStyle}>View in Shadowbox</p>
            <Shadowbox aspect='1.5rem' color={colors.white} />
          </div>
        </div>
      </div>
    </TwoColumns>
  );
};

export default SeriesPiece;
